<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12" lg="6">
        <base-material-chart-card
          :data="dailyIntrestedBuyers.data"
          :options="dailyIntrestedBuyers.options"
          :responsive-options="dailyIntrestedBuyers.responsiveOptions"
          color="#E91E63"
          hover-reveal
          type="Line"
        >

          <h4 class="card-title font-weight-light mt-2 ml-2">Number of interested buyers daily</h4>

        </base-material-chart-card>
      </v-col>

      <v-col cols="12" lg="6">
        <base-material-chart-card
          :data="dailySalesChart.data"
          :options="dailySalesChart.options"
          color="success"
          hover-reveal
          type="Line"
        >

          <h4 class="card-title font-weight-light mt-2 ml-2">Daily Sales</h4>
          
        </base-material-chart-card>
      </v-col>

      <v-col cols="12" sm="6">
        <base-material-stats-card
          color="info"
          icon="mdi mdi-cart"
          title="Total presales"
          :value="totalPresalesValue"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <base-material-stats-card
          color="success"
          icon="mdi-store"
          title="Presale sold amount"
          :value="totalPresalesAmountValue"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <base-material-stats-card
          color="primary"
          icon="mdi-server-network"
          title="Network"
          :value="(network == null) ? '' : network"
          sub-icon="mdi-tag"
          :sub-text="(version == null) ? '' : version"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <base-material-stats-card
          color="primary"
          icon="mdi-database"
          title="Last block heigth"
          :value="(block_heigth == null) ? '' : block_heigth"
          sub-icon="mdi-clock"
          sub-text="last block heigth"
        />
      </v-col>

      <v-col cols="12" sm="6" v-for="(result,key) in supply_total" :key="'denom-'+key">
        <base-material-stats-card
          color="primary"
          icon="mdi-package-variant"
          title="Total supply"
          :value="denomValue(result)"
          sub-icon="mdi-tag"
          :sub-text="result.denom"
        />
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Dashboard',

    data () {
      return {
        totalPresales: -1,
        totalPresalesAmount: -1,
        network: null,
        version: null,
        block_heigth: null,
        supply_total: [],
        dailySalesChart: {
          data: {
            labels: [],
            series: [],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 50,
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        dailyIntrestedBuyers: {
          data: {
            labels: [],
            series: [],
          },
          options: {
            axisX: {
              showGrid: false,
            },
            low: 0,
            high: 50,
            chartPadding: {
              top: 0,
              right: 5,
              bottom: 0,
              left: 0,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
      }
    },
    created(){
      let from = new Date()
      let to = new Date()
      from.setDate(from.getDate() - 6)
      let fromDay = from.getDay()
      for(let index = 0; index < 7; index++){
        if ((fromDay + index) < 7) {
          this.dailySalesChart.data.labels.push(this.$t('dashboard page.week_short.' + (fromDay + index)))
          this.dailyIntrestedBuyers.data.labels.push(this.$t('dashboard page.week_short.' + (fromDay + index)))
        }
        else{
          this.dailySalesChart.data.labels.push(this.$t('dashboard page.week_short.' + ((fromDay + index) - 7)))
          this.dailyIntrestedBuyers.data.labels.push(this.$t('dashboard page.week_short.' + ((fromDay + index) - 7)))
        }
      }
      this.get('/api/dashboard/presales/perday', {
        from : from.toISOString(),
        to: to.toISOString()
      }).then(data => {
        this.dailySalesChart.data.series.push(data.presales)
        this.dailyIntrestedBuyers.data.series.push(data.interested_buyers)
        this.dailySalesChart.options.high = Math.max(...data.presales) + 1
        this.dailyIntrestedBuyers.options.high = Math.max(...data.interested_buyers) + 1
      })
      this.get('/api/dashboard/presales/total').then(data => {
        this.totalPresales = data.total
        this.totalPresalesAmount = data.amount
      })
      this.get('/api/dashboard/testnet').then(data => {
        this.network = data.network
        this.version = data.version
        this.block_heigth = data.block_heigth
        this.supply_total = data.supply_total
      })
    },
    computed: {
      totalPresalesValue(){
        return (this.totalPresales == -1) ? '' : this.totalPresales.toString()
      },
      totalPresalesAmountValue(){
        return (this.totalPresalesAmount == -1) ? '' : 'DPC '+ this.totalPresalesAmount
      }
    },
    methods: {
      complete (index) {
        this.list[index] = !this.list[index]
      },
      denomValue(item){
        if(item.denom == 'udepo'){
          return item.amount + ' DPC'
        }
        return item.amount
      }
    },
  }
</script>
